var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.member)?_c('div',[_c('model-table',_vm._b({attrs:{"provider":_vm.actionsProvider,"columns":_vm.actionsColumns,"crud":_vm.$service.crud.householdMembersActions,"allowExpand":true,"getName":function (i) { return ((i.actionActionType.action.name) + " - " + (i.actionActionType.actionType.name)); },"viewPermission":function (r) { return true; }},scopedSlots:_vm._u([{key:"top-actions",fn:function(){return [_c('q-btn',{staticClass:"bg-green-5 text-white",attrs:{"icon":"add","label":"Add Service"},on:{"click":function($event){_vm.selectedAction = null;
          _vm.addActionDialog = true;
          _vm.editMode = false;}}}),_c('q-btn',{staticClass:"bg-green-5 text-white",attrs:{"icon":"add","label":"Add Service Wizard"},on:{"click":function($event){return _vm.$router.push({
            name: 'AddServiceWizard',
            params: { memberId: _vm.id }
          })}}})]},proxy:true},{key:"title",fn:function(){return [_c('q-card',{staticClass:"q-pa-sm q-mx-sm",staticStyle:{"background":"#e0e0e0"},attrs:{"flat":""}},[_c('div',{staticClass:"text-italic"},[_vm._v("Actions for")]),_c('div',[_vm._v(" "+_vm._s(((_vm.member.firstName) + " " + (_vm.member.lastName) + " (" + (_vm.member.dateOfBirth.split("T")[0]) + ")"))+" ")])]),_c('q-btn',{attrs:{"icon":"download","label":"Extract","flat":"","no-caps":""},on:{"click":function($event){_vm.extraction = true}}})]},proxy:true},{key:"expand-button",fn:function(ref){
          var scope = ref.scope;
return [_c('q-btn',{directives:[{name:"show",rawName:"v-show",value:(scope.row.actionActionType.actionType.hasSessions),expression:"scope.row.actionActionType.actionType.hasSessions"}],attrs:{"flat":"","no-caps":"","icon":scope.expand ? 'expand_less' : 'expand_more'},on:{"click":function($event){scope.expand = !scope.expand}}},[_c('q-badge',{attrs:{"color":"orange","floating":"","transparent":""}},[_vm._v(" "+_vm._s(scope.row["sessions@odata.count"])+" ")]),_c('q-tooltip',[_vm._v(" View Sessions ")])],1)]}},{key:"row-actions",fn:function(ref){
          var scope = ref.scope;
return [(!scope.row.actionActionType.actionType.hasSessions)?_c('q-btn',{attrs:{"icon":"question_answer","flat":"","no-caps":"","loading":_vm.$proxy.loadingSubmissions},on:{"click":function($event){_vm.$runAsync('loadingSubmissions', function () { return _vm.loadSubmission(scope.row.questionnaireSubmissionId); }
          )}}},[_c('q-tooltip',[_vm._v(" View and Edit Questionnaire Results ")])],1):_vm._e(),_c('q-btn',{attrs:{"disable":(scope.row.actionActionType.actionType.isLegal && !_vm.isLawyer) ||
            (scope.row.actionActionType.actionType.isPsychological && !_vm.isPsychologist),"icon":"edit","flat":"","no-caps":""},on:{"click":function($event){_vm.selectedAction = scope.row;
          _vm.addActionDialog = true;
          _vm.editMode = true;}}},[_c('q-tooltip',[_vm._v(" View and Edit Questionnaire Results ")])],1)]}},{key:"expanded",fn:function(ref){
          var scope = ref.scope;
return [_c('household-member-action-sessions-table',{staticStyle:{"margin-bottom":"20px"},attrs:{"actionId":scope.row.id,"viewPermission":function (r) { return true; },"disable":(scope.row.actionActionType.actionType.isLegal && !_vm.isLawyer) ||
            (scope.row.actionActionType.actionType.isPsychological && !_vm.isPsychologist)},on:{"edit":function($event){_vm.addSessionDialog = true;
          _vm.selectedAction = scope.row;
          _vm.selectedSession = $event;}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(scope.row.actionActionType.actionType.hasSessions)?_c('q-btn',{attrs:{"disable":(scope.row.actionActionType.actionType.isLegal && !_vm.isLawyer) ||
            (scope.row.actionActionType.actionType.isPsychological && !_vm.isPsychologist),"icon":"add","label":"Add Session","flat":"","no-caps":""},on:{"click":function($event){_vm.selectedSession = null;
              _vm.addSessionDialog = true;
              _vm.selectedAction = scope.row;}}}):_vm._e()]},proxy:true}],null,true)})]}}],null,false,4204909912)},'model-table',Object.assign({}, _vm.$props, _vm.$attrs),false)),_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.addActionDialog),callback:function ($$v) {_vm.addActionDialog=$$v},expression:"addActionDialog"}},[_c('add-household-member-action-form',{attrs:{"inDialog":true,"editMode":_vm.editMode,"memberId":_vm.id,"action":_vm.selectedAction},on:{"save":function($event){_vm.addActionDialog = false;
        _vm.loadActions();}}})],1),_c('q-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.addSessionDialog),callback:function ($$v) {_vm.addSessionDialog=$$v},expression:"addSessionDialog"}},[_c('add-household-member-session-form',{attrs:{"inDialog":true,"householdMemberAction":_vm.selectedAction,"item":_vm.selectedSession},on:{"update:item":function($event){_vm.selectedSession=$event},"save":function($event){_vm.addSessionDialog = false;
        _vm.loadActions();}}})],1),_c('q-dialog',{attrs:{"scrollable":"","persistent":""},model:{value:(_vm.showResults),callback:function ($$v) {_vm.showResults=$$v},expression:"showResults"}},[(_vm.submission)?_c('questionnaire-submit',{attrs:{"questionnaireId":_vm.submission.id,"inDialog":true},on:{"save":function($event){_vm.showResults = false}}}):_vm._e()],1),_c('q-dialog',{attrs:{"scrollable":"","persistent":""},model:{value:(_vm.extraction),callback:function ($$v) {_vm.extraction=$$v},expression:"extraction"}},[_c('services-extractions',{attrs:{"memberId":_vm.id,"memberName":((_vm.member.firstName) + " " + (_vm.member.lastName))}})],1),_c('router-view')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }