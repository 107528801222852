
















































































































































































import DataProvider from "@/lib/DataProvider";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HouseholdMembersActions from "@/data/columns/HouseholdMembersActions";
import ModelTable from "@/components/ModelTable.vue";
import AddHouseholdMemberActionForm from "@/components/forms/AddHouseholdMemberActionForm.vue";
import HouseholdMemberActionSessionsTable from "@/components/HouseholdMemberActionSessionsTable.vue";
import AddHouseholdMemberSessionForm from "@/components/forms/AddHouseholdMemberSessionForm.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import QuestionnaireSubmit from "@/components/forms/QuestionnaireSubmit.vue";
import ServicesExtractions from "@/components/extractionForms/ServicesExtractionsForm.vue";
import { saveAs } from "file-saver";
@Component({
  components: {
    ModelTable,
    AddHouseholdMemberActionForm,
    HouseholdMemberActionSessionsTable,
    AddHouseholdMemberSessionForm,
    DynamicForm,
    QuestionnaireSubmit,
    ServicesExtractions
  }
})
export default class HouseholdMemberActionsTable extends Vue {
  @Prop()
  public id!: string;

  private selectedSession: any = null;
  private actionsProvider: DataProvider<any> | null = null;
  private actionsColumns = HouseholdMembersActions;
  private addActionDialog = false;
  private addSessionDialog = false;
  private selectedAction = null;
  private submission: any = null;
  private answers: any = null;
  private showResults = false;
  private editMode = false;
  private member: any = null;
  private extraction = false;
  private isLawyer = false;
  private isPsychologist = false;

  async created() {
    const user = await this.$service.providers.users.fetchItemAsync(
      this.$auth.userInfo.profile.sub
    );
    this.isLawyer = this.$auth.permissions.admin.is || user.isLawyer;
    this.isPsychologist =
      this.$auth.permissions.admin.is || user.isPsychologist;
    this.loadActions();
    this.member = await this.$service.providers.householdMembers.fetchItemAsync(
      this.id
    );
  }
  loadActions() {
    this.actionsProvider = this.$service.providers.householdMembersActions(
      this.id
    );
  }

  async loadSubmission(id: string) {
    this.submission = { id };

    this.showResults = true;
  }
}
