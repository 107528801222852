
































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {
    FormContainer,
    DatePicker
  }
})
export default class ServicesExtractionsForm extends Vue {
  @Prop()
  public provider!: any;

  @Prop()
  public memberId!: string;

  @Prop()
  public memberName!: string;

  private actionTypes: any = [];
  private projects: any = [];
  private filters: any = [];
  private downloadName: any = "";
  private selectedAction: any = null;

  @Watch("filters", { deep: true })
  onItemChange() {
    if (!this.filters) {
      this.initializeItem();
    }
    if (this.filters.actionType) {
      this.filters.actionType === "Legal Services"
        ? (this.filters.hasSessions = true)
        : (this.filters.hasSessions = null);
      this.filters.hasNextStep = null;
    }
  }

  async mounted() {
    const actions = (
      await this.$service.providers
        .householdMembersActions(this.memberId)
        .fetchItemsAsync()
    ).items;
    if (this.memberName) {
      this.downloadName = `${this.memberName}_Services`;
    }

    const actionType = actions
      .flatMap((i: any) => i.actionActionType.actionType)
      .filter((x: any) => x);
    this.actionTypes = this.getDinstinct(actionType.map((i: any) => i.name));

    const proj = actions.flatMap((i: any) => i.project).filter((x: any) => x);
    this.projects = this.getDinstinct(proj.map((i: any) => i.title));

    this.initializeItem();
  }

  getDinstinct(arr: any) {
    return Array.from(
      arr.filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      )
    );
  }

  initializeItem() {
    this.filters = {
      memberId: this.memberId,
      projects: [],
      actionType: null,
      hasSessions: null,
      hasNextStep: null,
      startDate: null,
      endDate: null,
      hasScheduledLegal: false
    };
  }
}
