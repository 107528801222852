export default [
  {
    name: "actionActionType/Action/name",
    required: true,
    label: "Service",
    align: "left",
    sortable: true,
    field: (i: any) =>
      `${i.actionActionType.action.name} - ${i.actionActionType.actionType.name}`
  },
  {
    name: "description",
    required: true,
    label: "Description",
    align: "left",
    sortable: true,
    field: (i: any) => (i.description != null ? i.description : " ")
  },
  {
    name: "project/title",
    required: true,
    label: "Project Title",
    align: "left",
    sortable: true,
    field: (i: any) => (i.project ? i.project.title : "")
  },
  {
    name: "status",
    required: true,
    label: "Legal Case Status",
    align: "left",
    sortable: true,
    field: (i: any) => i.actionActionType.actionType.name === 'Legal Services' ? i.status : ''
  },
  {
    name: "startDate",
    required: true,
    label: "Start Date",
    align: "left",
    sortable: true,
    field: (i: any) => (i.startDate ? new Date(Date.parse(i.startDate)) : undefined),
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "endDate",
    required: true,
    label: "Date Updated",
    align: "left",
    sortable: true,
    field: (i: any) => (i.endDate ? new Date(Date.parse(i.endDate)) : undefined),
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "companies",
    required: true,
    label: "Companies",
    align: "left",
    sortable: true,
    field: (i: any) => (i.companies ? i.companies.map((o: any) => o.name).join(", ")  : '')
  }
];
